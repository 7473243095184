<template>
  <div class="page_box">
    <div class="active_header">
      <img :src="bannder"/>
    </div>
    <div class="active_name">
      <span>{{ title }}</span>
    </div>
    <div class="box">
      <template v-if="activitys_data.length != 0">
        <activitysItem
            v-for="(item, i) in activitys_data"
            :key="i"
            :class="{ is_left: (i + 1) % 2 == 1 }"
            :default_img="default_img"
            :img_important="img_important"
            :item="item"
        ></activitysItem>
      </template>
      <div class="loading_all_center">
        <isAllDataTip
            v-if="total != 0 && total == activitys_data.length"
        ></isAllDataTip>
        <isLoadingTip v-if="loading"></isLoadingTip>
      </div>
      <noDataImg v-if="activitys_data.length == 0 && !loading"/>
    </div>
  </div>
</template>
<script>
import carouselBanner from '@/baseComponents/carouselBanner'
import noDataImg from "@/baseComponents/noDataImg";
import isLoadingTip from "@/baseComponents/isLoadingTip";
import isAllDataTip from "@/baseComponents/isAllDataTip";
import activitysItem from "~tif/components/index/activitys/activitysItem";

export default {
  components: {
    noDataImg,
    isLoadingTip,
    isAllDataTip,
    activitysItem,
    carouselBanner,
  },
  data() {
    return {
      is_all: false,
      bannder:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/Banner_tree.png",
      title: "",
      default_img: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png",
      default_xhdt:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffaApplent/home/active.png",
      default_hyxx:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/xhhz.jpg",
      default_zcfg:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/Policies_regulations.png",
      default_hdtz:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/InformationIndustry.png",
      default_hyzf:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/hyzf.jpg",
      img_important:
          "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/important.png",
      img_hyxx: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active2.jpg',
      img_xhdt1:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationActivities.png',
      img1:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/contenttypeevent.png',
      img2:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/logo_2.png',
      img3:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/logo_1.png',
      img4: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/importantAnnunciate.png",
      img5: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/comapny_dynamic.png",
      img6: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/association_news.png",
      img7: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/tiffaSociety/consult.png",
      loading: false,
      total: 6,
      currentPage: 1,
      content_type: "",
      sub_type: null,
      activitys_data: [],
      bannerType: 1,
    };
  },
  watch: {
    '$route'(to, from) {
      this.setTitle()
      this.getData()
      this.getList(1)
    },
    title(nv){
    }
  },
  created() {
    this.setTitle()
  },
  mounted() {
    this.getData()
    this.setTitle()

  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollEvent, false);
  },
  methods: {
    setTitle() {
      let content_type = this.$route.query.content_type
      if (content_type == 73) {
        this.title = "通知公告";
        this.default_img = this.default_xhdt;
      }else if (content_type == 74) {
        this.title = "新闻快递";
        this.default_img = this.default_xhdt;
      } else if (content_type == 75) {
        this.title = "货代知识";
        this.default_img = this.default_xhdt;
      }else if (content_type == 4) {
        this.title = "政策法规";
        this.default_img = this.default_xhdt;
      }else if (content_type == 76) {
        this.title = "考试培训";
        this.default_img = this.default_xhdt;
      }else if (content_type == 77) {
        this.title = "已备案企业";
        this.default_img = this.default_xhdt;
      }else if (content_type == 78) {
        this.title = "未备案企业";
        this.default_img = this.default_xhdt;
      }else if (content_type == 79) {
        this.title = "已交纳企业";
        this.default_img = this.default_xhdt;
      }else if (content_type == 80) {
        this.title = "未交纳企业";
        this.default_img = this.default_xhdt;
      }else if (content_type == 81) {
        this.title = "企业资质及获得表彰情况";
        this.default_img = this.default_xhdt;
      }else if (content_type == 82) {
        this.title = "专项服务";
        this.default_img = this.default_xhdt;
      }else if (content_type == 83) {
        this.title = "行业联谊";
        this.default_img = this.default_xhdt;
      }else {
        this.$router.push({
          name: "index",
        });
      }
      document.title = this.title;
      
    },
    getData() {
      this.content_type = this.$route.query.content_type
      if (this.$route.query.sub_type) {
        this.sub_type = this.$route.query.sub_type
      }
      this.getList(1);
      window.removeEventListener("scroll", this.scrollEvent, false);
      window.addEventListener("scroll", this.scrollEvent, false);
    },
    scrollEvent() {
      if (
          document.documentElement.scrollTop +
          document.documentElement.clientHeight >=
          document.body.scrollHeight - 354
      ) {
        if (this.total != this.activitys_data.length) {
          if (!this.is_all) {
            this.currentPage++;
            this.getList(2);
            // 防止多次出发 请求事件
            window.addEventListener("scroll", this.scrollEvent, false);
          }
        }
      }
    },
    async getList(type) {
      //type 1 条件查询  type 2 触底出发
      // 防止多次出发 请求事件
      window.removeEventListener("scroll", this.scrollEvent, false);
      this.loading = true;
      let params = {};
      params.content_type = this.$route.query.content_type;
      if(this.$route.query.sub_type){
        params.sub_type=this.$route.query.sub_type
      }
      params.start = this.currentPage - 1;
      params.limit = 6;
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      let res = await this.$store.dispatch(
          "API_index/getStaticContent",
          params
      );
      if (res.success) {
        window.addEventListener("scroll", this.scrollEvent, false);
        this.loading = false;
        this.total = res.total;
        if (type == 2) {
          if (res.data.length == 0) {
            this.is_all = true;
          }
          this.activitys_data.push(...res.data);
        } else if (type == 1) {
          this.activitys_data = res.data;
        }
      }
    },
  },
};
</script>
<style lang="less" scoped>
.page_box {
  background: #fafafa;
  img {
    width: 100%;
  }
}

.active_header {
  position: relative;
  padding-top: 14.6%;
  overflow: hidden;

  img {
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.active_name {
  padding: 24px;
  text-align: center;

  span {
    width: 84px;
    height: 29px;
    font-size: 21px;
    font-weight: 800;
    color: #1f292e;
    line-height: 29px;
  }
}

.box {
  width: 1200px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  min-height: 300px;
  position: relative;

  .is_left {
    margin-right: 24px;
  }

  .loading_all_center {
    padding: 15px;
    width: 100%;
    text-align: center;
  }
}
</style>